@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Product Sans";
  src: local("Product Sans"), local("Product Sans"),
    url("./assets/fonts/Product\ Sans\ Regular.ttf") format("truetype");
}

:root {
  --font-family-sans-serif: "Product Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body,
html {
  background-color: #fffaec;
  box-sizing: border-box;
  font-family: var(--font-family-sans-serif);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  word-spacing: 1px;
  -webkit-text-size-adjust: 100%;
  outline: none;
  font-size: 16px;
  scroll-behavior: smooth;

  :focus {
    outline: none;
  }

  * {
    font-family: var(--font-family-sans-serif);
  }
}

.hover-slack-button:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
